export default {
    distinct: function(array){
        var r = [];
        for(var i = 0, l = array.length; i<l; i++){
          for(var j = i + 1; j < l; j++)
            if(array[i] == array[j]) j == ++i;
          r.push(array[i]);
        }
        return r;
      }
}