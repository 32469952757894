<template>
    <div class="container">
      <!-- Table -->
      <div class="row justify-content-center">
        <div class="col">
            <el-form ref="form" :model="model" :show-message="false" label-width="auto" label-position="top" v-loading="loading">
                <div class="row">
                    <div class="col-md-6">
                    <label class="form-label required">工作队</label>
                    <el-form-item prop="team" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.team" placeholder="填写工作队"
                            :fetch-suggestions="queryT">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label required">原单位</label>
                    <el-form-item prop="company" :rules="[{required:true}]">
                        <el-autocomplete v-model="model.company" placeholder="填写原单位"
                            :fetch-suggestions="queryC">
                        </el-autocomplete>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-4">
                    <label class="form-label required">干部姓名</label>
                    <el-form-item prop="name" :rules="[{required:true}]">
                        <el-input v-model="model.name" placeholder="填写干部姓名" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-4">
                    <label class="form-label required">干部电话</label>
                    <el-form-item prop="phone" :rules="[{required:true}]">
                        <el-input type="tel" v-model="model.phone" placeholder="填写干部电话" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-4">
                    <label class="form-label required">关系</label>
                    <el-form-item prop="relation_ship" :rules="[{required:true}]">
                        <el-select v-model="model.relation_ship" placeholder="选择关系">
                            <el-option label="本人" value="本人"></el-option>
                            <el-option label="父母" value="父母"></el-option>
                            <el-option label="夫妻" value="夫妻"></el-option>
                            <el-option label="子女" value="子女"></el-option>
                            <el-option label="亲戚" value="亲戚"></el-option>
                            <el-option label="朋友" value="朋友"></el-option>
                            <el-option label="其他" value="其他"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">收件人</label>
                    <el-form-item prop="receiver_name" :rules="[{required:true}]">
                        <el-input v-model="model.receiver_name" placeholder="填写收件人" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-3">
                    <label class="form-label required">收件电话</label>
                    <el-form-item prop="receiver_phone" :rules="[{required:true}]">
                        <el-input type="tel" v-model="model.receiver_phone" placeholder="填写收件电话" required></el-input>
                    </el-form-item>
                    </div>
                    <div class="col-md-6">
                    <label class="form-label required">收件地址</label>
                    <el-form-item prop="receiver_address" :rules="[{required:true}]">
                        <el-input v-model="model.receiver_address" placeholder="填写收件地址" required></el-input>
                    </el-form-item>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-3">
                    <label class="form-label required">配送要求</label>
                    <el-form-item prop="deliver_time" :rules="[{required:true}]">
                        <el-select v-model="model.deliver_time" placeholder="请选择配送要求">
                            <el-option label="工作日" value="工作日"></el-option>
                            <el-option label="周六日" value="周六日"></el-option>
                            <el-option label="不限" value="不限"></el-option>
                        </el-select>
                    </el-form-item>
                    </div>
                    <div class="col-md-9">
                    <label class="form-label">备注</label>
                    <el-form-item>
                        <el-input v-model="model.remark"></el-input>
                    </el-form-item>
                    </div>
                </div>
                <div class="text-center">
                    <button type="button" class="btn btn-primary mt-4 ls-3" @click="submit">保存</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="cancel">取消</button>
                    <button type="button" class="btn btn-secondary mt-4 ls-3" @click="del">删除</button>
                </div>
            </el-form>
        </div>
      </div>
    </div>
</template>

<script>
//import { Form, FormItem, Autocomplete} from 'element-ui';
import utils from '@/utils'
export default {
    // components: {
    //     [Form.name]: Form,
    //     [FormItem.name]: FormItem,
    //     [Autocomplete.name]: Autocomplete
    // },
    props: {
        modeldata: {
            type: Object,
            description: "the model object to update",
            default: null
        },
        group: {
            type: String,
            default: ''
        },
        list: {
            type: Array,
            description: "the saved models",
            default: null
        },
        saved: {
            type: Boolean,
            description: "indicating data saved",
            default: false
        },
        cancelled: {
            type: Boolean,
            description: "indicating wether editing is cancelled",
            default: false
        }
    },
    data() {
      return {
        tmp_modeldata: this.modeldata,
        tmp_saved: this.saved,
        tmp_cancelled: this.cancelled,
        loading: false,
        model: {
            team: '',
            group: this.group,
            company: '',
            name: '', 
            phone: '', 
            relation_ship: '', 
            receiver_name: '', 
            receiver_phone: '',  
            receiver_address: '', 
            deliver_time: '', 
            remark: ''
        }
      }
    },
    computed: {
        profiles() {
            return this.$store.state.profiles.profiles || {}
        }
    },
    watch: {
        modeldata(v) {
            // if "modeldata" changed, means another Counter is passed to be edit
            // we need to refresh the form config
            this.model = JSON.parse(JSON.stringify(v));
            this.tmp_cancelled = false;
            this.tmp_saved = false;
        },
        tmp_modeldata(v) {
            this.$emit("update:modeldata", v);
        },
        tmp_saved(v) {
            // telling parents component the data is saved
            this.$emit("update:saved", v);
        },
        tmp_cancelled(v) {
            // telling parents component user cancelled editing
            this.$emit("update:cancelled", v);
        }
    },
    methods: {
        query(field, query, callback) {
            var res = query ? this.list.filter((x)=>{ return x[field].indexOf(query) >= 0 }) : this.list;
            res = res.map((x)=>{ return { value: x[field] } })
            callback(utils.distinct(res))
        },
        queryT(query, cb) { this.query('team', query, cb); },
        queryC(query, cb) { this.query('company', query, cb); },
        submit() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.save();
                } else {
                    return false;
                }
            });
        },
        save() {
            this.loading = true;
            if(!this.model.group) this.model.group = this.group;
            this.axios({
                method: this.model.id ? 'put' : 'post', // put for update, post for create
                url: this.model.id ? 'songcais/' + this.model.id : 'songcais',
                data: this.model
            }).then((res) => {
                this.loading = false;
                if (res.data.errcode == 0) {
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('保存失败', res.data.errmsg);
                }
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('保存失败', err.response.data.error.message);
                    else this.$notifyErr('保存失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', 'Failed: ' + err.message);
                this.loading = false;
            })
        },
        cancel() {
            this.tmp_cancelled = true
        },
        del(){
            this.loading = true;
            if(!this.model.group) this.model.group = this.group;
            this.axios({
                method: 'delete', 
                url: this.model.id ? 'songcais/' + this.model.id : 'songcais',
                data: this.model
            }).then((res) => {
                if (res.data.errcode == 0) {
                    this.$notifyErr('已删除', res.data.errmsg);
                    this.tmp_saved = true;
                }
                else {
                    this.$notifyErr('删除失败', res.data.errmsg);
                }
                this.loading = false;
            }).catch((err) => {
                if(err.response) {
                    if(err.response.data.error) this.$notifyErr('删除失败', err.response.data.error.message);
                    else this.$notifyErr('删除失败', err.response.data[0].message);
                }
                else this.$notifyErr('保存失败', 'Failed: ' + err.message);
                this.loading = false;
            })
            this.loading = false;
        }
    },
    mounted() {
        this.tmp_cancelled = false;
        this.tmp_saved = false;
        if(this.tmp_modeldata)
            this.model = JSON.parse(JSON.stringify(this.tmp_modeldata));
    }
};
</script>
<style scoped>
    .el-select, .el-autocomplete {
        width: 100%;
    }
    label.required::before {
        content: '*';
        color: red;
        position: absolute;
        top: 0;
        left: 6px;
        font-weight: 300;
        font-size: smaller;
    }
</style>